


import Component from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import { TranslateResult } from 'vue-i18n';
import CompanyMediaBlock from '@/_modules/promo/components/company-media-block/company-media-block.vue';

@Component({
  name: 'promo-live-broadcasts',
  components: {
    CompanyMediaBlock,
  },
  computed: {
    ...mapGetters({
      isPromoPageListLoading: 'promoStore/promoPageListLoading',
      promoPageList: 'promoStore/promoPages',
    }),
  }
})
export default class PromoLiveBroadcasts extends Vue {

  public readonly isPromoPageListLoading: boolean;
  public readonly promoPageList: TPromoPage[];

  public promoPageListSlices: TPromoPage[][] = [];
  public standTexts: { [key: string]: TranslateResult };

  private promoPageListSliceIntervalId: number;
  private promoPageListSliceAmount: number = 60;

  public created(): void {
    this.standTexts = {
      inactive: this.$t('organizerCabinet.sections.lobby.standBroadcastInactive'),
      checking: this.$t('organizerCabinet.sections.lobby.standBroadcastChecking'),
    };
    this.callPromoPageList();
  }

  public getCompanyLogo(companyLogo: string): string {
    return companyLogo || require('@/assets/images/no-avatar-company-300x300.png');
  }

  @Watch('promoPageList', { immediate: true })
  private onPromoPageListChange(): void {
    this.startPromoPageListSlicing();
  }

  private startPromoPageListSlicing(): void {
    this.stopPromoPageListSlicing();

    this.promoPageListSlices = [];

    if (!this.promoPageList || !this.promoPageList.length) {
      return;
    }
    this.setPromoPageListNextSlice();
    this.promoPageListSliceIntervalId = window.setInterval(() => {
      this.setPromoPageListNextSlice();
    }, 500);
  }

  private stopPromoPageListSlicing(): void {
    if (!this.promoPageListSliceIntervalId) {
      return;
    }
    clearInterval(this.promoPageListSliceIntervalId);
    this.promoPageListSliceIntervalId = null;
  }

  private setPromoPageListNextSlice(): void {
    if (this.promoPageListSlices.length >= Math.ceil(this.promoPageList.length / this.promoPageListSliceAmount)) {
      return;
    }
    const from = this.promoPageListSlices.length * this.promoPageListSliceAmount;
    const to = from + this.promoPageListSliceAmount;

    this.promoPageListSlices.push(this.promoPageList.slice(from, to));
  }

  private callPromoPageList(): void {
    this.$store.dispatch('promoStore/promoPageListAll', { event_id: this.$route.params.eventId });
  }

}
